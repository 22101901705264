<template>
  <div style="width: 100%; float: right" class="fill-height">
    <v-row class="pt-3 pr-3 ml-16">
      <v-col cols="2"> </v-col>
      <v-col cols="8" class="pt-6">
        <categories-filter
          ref="categoriesFilter"
          :items="items"
          @setIdsForFilter="onSetIdsForFilter"
        ></categories-filter>
      </v-col>
      <v-col cols="2">
        <Search-box
          :emitDestination="searchEmitDestination"
          searchPlaceholder="Pretraži artikle"
        ></Search-box>
      </v-col>
    </v-row>
    <v-container>
      <v-row
        v-if="listView"
        style="margin-top: 0px"
        class="d-flex justify-center"
      >
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card v-for="(item, i) in items" :key="i" hover>
            <v-row
              :class="[
                'ma-0',
                item.status === 'OK'
                  ? 'status-ok-exp'
                  : `${
                      item.status === 'PROCESSING'
                        ? 'status-processing-exp'
                        : 'status-failed-exp'
                    }`,
              ]"
              style="height: 48px !important"
            >
              <div
                style="width: 6% !important"
                v-if="density !== 'greater'"
                :class="[densityPadding]"
              >
                <v-img
                  v-if="item.picture"
                  :src="item.picture"
                  contain
                  :height="density === 'normal' ? '44' : '68'"
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/no-item.jpg"
                  :height="density === 'normal' ? '44' : '68'"
                ></v-img>
              </div>

              <!-- <div   style="min-width: 100px; max-width: 100%">

              </div> -->
              <div
                style="
                  padding-left: 5px;
                  width: 33% !important;
                  padding-bottom: 10px !important;
                "
                :class="[densityPadding]"
              >
                <div class="caption grey--text">Naziv</div>
                <div
                  class="one-liner-text"
                  style=""
                  @mouseover="hoverOver(`hover-${item.id}`, item)"
                  @mouseleave="item[`hover-${item.id}`] = false"
                  :id="`hover-${item.id}`"
                  :ref="`hover-${item.id}`"
                >
                  {{ $options.filters.capitalize(item.name) }}
                </div>
                <v-tooltip v-model="item[`hover-${item.id}`]" bottom>
                  <!--Fake activator to avoid an attach property which is not working properly -->
                  <template v-slot:activator="{ on }">
                    <div v-on="on"></div>
                  </template>
                  <div style="max-width: 300px">{{ item.name }}</div>
                </v-tooltip>
              </div>
              <div style="width: 10% !important" :class="[densityPadding]">
                <div class="caption grey--text text-left">Cijena</div>
                <div
                  class="text-left"
                  v-for="p in item.prices"
                  :key="p.currency"
                >
                  <div v-if="item.manual_price_input">- {{ p.currency }}</div>
                  <div v-if="!item.manual_price_input && p.currency !== 'HRKX'">
                    {{ p.price | money(100, p.currency) }}
                  </div>
                </div>
              </div>
              <div style="width: 5% !important" :class="[densityPadding]">
                <div class="caption grey--text text-sm-center text-md-left">
                  PDV
                </div>
                <div class="text-sm-center text-md-left">
                  {{ showTax(item) }}
                </div>
              </div>
              <div style="width: 5% !important" :class="[densityPadding]">
                <div class="caption grey--text text-sm-center text-md-left">
                  {{ havePnp(item) ? "PNP" : "" }}
                </div>
                <div class="text-sm-center text-md-left">
                  {{ havePnp(item) ? showPnp(item) : "" }}
                </div>
              </div>
              <div style="width: 7% !important" :class="[densityPadding]">
                <div class="caption grey--text text-md-left">Šifra</div>
                <div class="text-md-left">{{ item.code }}</div>
              </div>
              <div style="width: 8% !important" :class="[densityPadding]">
                <div class="caption grey--text text-md-center">Stanje</div>
                <div class="text-center">
                  {{ item.type === "GOODS" ? item.stock : "-" }}
                </div>
              </div>
              <v-tooltip bottom>
                <template #activator="{ on: on }">
                  <div
                    v-on="on"
                    :class="[densityPadding]"
                    style="width: 7% !important"
                  >
                    <div class="caption grey--text">Status</div>
                    <div
                      :class="[
                        item.status === 'OK'
                          ? 'success--text'
                          : `${
                              item.status === 'PROCESSING'
                                ? 'info--text'
                                : 'error--text'
                            }`,
                      ]"
                    >
                      {{ translateStatus(item.status) }}
                    </div>
                  </div>
                </template>
                {{ getStatusTooltip(item.status) }}
              </v-tooltip>
              <div style="width: 8% !important" :class="[densityPadding]">
                <div class="caption grey--text">Prodaje se</div>
                <div
                  :class="[
                    checkActivePrice(item) === 'DA'
                      ? 'success--text'
                      : `${
                          item.status === 'PROCESSING'
                            ? 'info--text'
                            : `${
                                checkActivePrice(item) === 'NE'
                                  ? 'error--text'
                                  : ''
                              }`
                        }`,
                  ]"
                >
                  {{ checkActivePrice(item) }}
                  <!-- {{ item.active === true ? "DA" : "NE" }} -->
                </div>
              </div>
              <div :class="[densityPadding]" style="width: 10% !important">
                <div class="d-flex align-start justify-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="openEditForm(item)"
                        @keydown.enter="openEditForm(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Uredi artikl</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="item.status && item.status.includes('oes not exist')"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="fixItem(item)"
                        @keydown.enter="fixItem(item)"
                      >
                        <v-icon>mdi-auto-fix</v-icon>
                      </v-btn>
                    </template>
                    <span>Ispravi status</span>
                  </v-tooltip>
                </div>
              </div>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="d-flex justify-center align-center fill-height ma-0 pa-0"
      >
        <v-card
          class="align-center ma-5"
          v-for="(item, i) in items"
          :key="i"
          hover
          height="300"
          width="250"
        >
          <v-img
            height="150"
            width="250"
            v-if="item.picture"
            :src="item.picture"
          >
          </v-img>
          <v-img height="165" width="250" v-else src="@/assets/no-item.jpg">
          </v-img>
          <div class="mr-4 ml-4">
            <div>
              <h3 class="selling-point-title-text one-liner-text">
                {{ $options.filters.capitalize(item.name) }}
              </h3>
              <p class="grey--text mb-1 selling-point-text one-liner-text">
                Status: {{ item.status }}
              </p>
              <p class="grey--text mb-1 selling-point-text one-liner-text">
                Cijena: {{ item.price | money(100) }}
              </p>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more" class="text--secondary font-italic"></div>
        <div slot="no-results" class="text--secondary font-italic"></div>
      </infinite-loading>
    </v-container>
    <add-pricelist-item
      ref="addPricelistItem"
      @success="refreshPriceList"
    ></add-pricelist-item>
    <add-wh-pricelist-item
      ref="addWhPricelistItem"
      @success="refreshPriceList"
    ></add-wh-pricelist-item>
    <edit-pricelist-item
      ref="editPricelistItem"
      @success="refreshPriceList"
    ></edit-pricelist-item>
    <edit-wh-pricelist-item
      ref="editWhPricelistItem"
      @success="refreshPriceList"
    ></edit-wh-pricelist-item>
    <add-warehouse-item
      ref="addWarehouseItem"
      @success="startFixProcess"
    ></add-warehouse-item>
    <add-menu-item ref="addMenuItem" @refresh="refresh"></add-menu-item>
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import addPricelistItem from '@/modules/point-of-sale/components/AddPricelistItem'
import addMenuItem from '@/modules/point-of-sale/components/menu-item/AddMenuItem'
import CategoriesFilter from '@/components/CategoriesFilter.vue'
import addWhPricelistItem from '@/modules/point-of-sale/components/AddWhPricelistItem'
import editPricelistItem from '@/modules/point-of-sale/components/EditPricelistItem'
import editWhPricelistItem from '@/modules/point-of-sale/components/EditWhPricelistItem'
import addWarehouseItem from '@/modules/point-of-sale/components/AddWarehouseItem'
import InfiniteLoading from 'vue-infinite-loading'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
import EventBus from '@/plugins/event-bus'
import { collection, query, onSnapshot, orderBy, where, limit, startAfter } from 'firebase/firestore'

export default {
  name: 'Pricelist',
  inject: ['showLoader', 'hideLoader', 'showMsgBox'],
  mixins: [flow, applicationSettings, barcodeChecker],
  components: { addMenuItem, addPricelistItem, editPricelistItem, InfiniteLoading, addWarehouseItem, addWhPricelistItem, editWhPricelistItem, CategoriesFilter },
  data: () => ({
    dialog: false,
    loading: false,
    totalArticles: 0,
    lastVisible: '',
    multiplier: 0,
    lastiItem: false,
    items: [],
    initialItems: [],
    warehouseModule: false,
    searchEmitDestination: 'pricelistSearch',
    lastDocSnapshot: null,
    filters: [],
    searchObject: undefined
  }),
  computed: {
    listView () {
      return state.isListView()
    }
    // search () {
    //   return state.search
    // }
  },
  mounted () {
    if (state.getCurrentCompany().warehouse === 'module') {
      this.warehouseModule = true
    }

    EventBus.$off(this.searchEmitDestination)
    EventBus.$on(this.searchEmitDestination, (searchObject) => {
      this.searchObject = searchObject
      this.lastDocSnapshot = null
      this.detachListeners()
      this.items = [] // Očisti postojeće artikle
      this.$nextTick(() => {
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      })
    })

    this.getMultiplier()
  },
  watch: {
    listView (nv, ov) {
      if (nv !== ov) {
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    }
    // search () {
    //   this.lastVisible = ''
    //   this.items = []
    //   if (this.$refs.InfiniteLoading) {
    //     this.$refs.InfiniteLoading.stateChanger.reset()
    //   }
    // }
  },
  methods: {
    refresh () {
      this.lastDocSnapshot = null
      this.detachListeners()
      this.resetHandler()
    },
    onSetIdsForFilter (ids) {
      this.filters = []
      this.filters.push(ids)
      this.lastDocSnapshot = null
      this.resetHandler()
    },
    resetHandler () {
      this.items = []
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    translateStatus (status) {
      if (!status) return ''
      if (status.toUpperCase() === 'OK' || status.toUpperCase() === 'PROCESSING') {
        return status
      } else {
        return this.$t('$vuetify.errors.errorTitle')
      }
    },
    getStatusTooltip (status) {
      if (!status) return ''
      if (status.toUpperCase() === 'OK' || status.toUpperCase() === 'PROCESSING') {
        return status
      } else if (status.toUpperCase() === 'DUPLICATE') {
        return this.$t('$vuetify.errors.duplicateItem')
      } else {
        return status
      }
    },
    showTax (item) {
      var pdv = 0
      if (item && item.prices && item.prices.EUR && item.prices.EUR.taxes) {
        item.prices.EUR.taxes.forEach(tax => {
          if (tax.name !== 'PNP') {
            pdv = tax.rate / 100 + '%'
          }
        })
      }
      return pdv
    },
    showPnp (item) {
      var pnp = 0
      if (item && item.prices && item.prices.EUR && item.prices.EUR.taxes) {
        item.prices.EUR.taxes.forEach(tax => {
          if (tax.name === 'PNP') {
            pnp = tax.rate / 100 + '%'
          }
        })
      }
      return pnp
    },
    havePnp (item) {
      var havePnp = false
      if (item && item.prices && item.prices.EUR && item.prices.EUR.taxes) {
        item.prices.EUR.taxes.forEach(tax => {
          if (tax.name === 'PNP') {
            havePnp = true
          }
        })
      }
      return havePnp
    },
    checkActivePrice (item) {
      var active = 'NE'
      if (item.prices && item.active) {
        Object.keys(item.prices).forEach(currency => {
          if (item.prices[currency].active === true) {
            active = 'DA'
          }
        })
      }
      return active
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].clientHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    openDialog () {
      this.dialog = true
    },
    openAddMenuItem () {
      this.$refs.addMenuItem.open()
    },
    openAddPricelistItemForm () {
      if (this.warehouseModule) {
        this.$refs.addWhPricelistItem.open()
      } else {
        this.$refs.addPricelistItem.open()
      }
    },
    openEditForm (item) {
      if (this.warehouseModule) {
        this.$refs.editWhPricelistItem.open(item)
      } else {
        this.$refs.editPricelistItem.open(item)
      }
    },
    fixItem (item) {
      this.confirm({
        title: 'Aktiviraj artikl',
        message: 'Da li ste sigurni da želite aktivirati artikl na trenutnom prodajnom mjestu?',
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'Aktiviraj artikl',
          cancelText: this.$t('$vuetify.close')
        }
      }).then(resp => {
        if (resp) {
          this.confirmClose()
          this.showLoader()
          this.$refs.addWarehouseItem.submit(item, true) // first add item to warehouse
        }
      })
    },
    startFixProcess (resp) {
      setTimeout(() => {
        this.$refs.editPricelistItem.open(resp.item, true) // than again add price
        this.$nextTick(() => {
          this.$refs.editPricelistItem.submit(true)
          this.hideLoader()
        })
      }, 2500)
    },

    refreshPriceList (item) {
      if (item.action === 'ADD') {
        this.items.unshift(
          { ...item, ...{ status: 'PROCESSING' } }
        )
        this.$vuetify.goTo(0)
      }

      if (item.action === 'EDIT') {
        df.doc(`location_pricelist/${state.getPointOfSale().id}/items/${item.id}`).update({ status: 'PROCESSING' })
        const idx = this.items.findIndex(it => it?.id === item.id)
        if (idx > -1) {
          this.items[idx].status = 'PROCESSING'
          this.$forceUpdate()
        }
      }

      const listener = df.doc(`location_pricelist/${state.getPointOfSale().id}/items/${item.id}`)
        .onSnapshot(doc => {
          if (doc && doc.data()) {
            var newItem = doc.data()

            if (newItem.status === 'OK') {
              const idx = this.items.findIndex(it => it?.id === item.id)

              if (idx > -1) {
                newItem.stock = newItem.stock / this.multiplier
                newItem.stock = newItem.stock.toString().replace('.', ',')
                if (newItem.prices) {
                  Object.keys(newItem.prices).forEach(key => {
                    newItem.prices[key].currency = key
                  })
                }

                this.items[idx] = newItem
              }

              this.$forceUpdate()
            }
          }
        })

      this.listeners.push(listener)

      // OLD CODE
      // if (item.action === 'ADD') {
      //   this.items.unshift(
      //     { ...item, ...{ status: 'PROCESSING' } }
      //   )
      //   this.$vuetify.goTo(0)
      // } else if (item.action === 'EDIT') {
      //   this.items = this.items.map(_item => {
      //     if (_item.id === item.id) {
      //       _item.name = item.name
      //       _item.status = 'PROCESSING'
      //     }
      //     return _item
      //   })
      // }
    },
    async infiniteHandler ($state) {
      if (this.isLoading) return
      this.isLoading = true

      try {
        const newItemsCount = await this.getItemsNew()
        if (newItemsCount > 0) {
          $state.loaded()
        } else {
          $state.complete()
        }
      } catch (error) {
        console.error('Error in infiniteHandler:', error)
        $state.error()
      } finally {
        this.isLoading = false
      }
    },

    async getItemsNew () {
      // if (!triGramObject && this.triGramObject) { triGramObject = this.triGramObject }
      var triGramObject = this.searchObject?.triGramObject

      const searchConstraints = []
      if (triGramObject) {
        Object.keys(triGramObject).forEach(name =>
          searchConstraints.push(where(`meta.${name}`, '==', true))
        )
      }
      if (this.filters.flat().length > 0) {
        const filters = this.filters.flat()
        searchConstraints.push(where('category_ids', 'array-contains-any', filters))
      }

      if (!triGramObject) {
        searchConstraints.push(orderBy('name', 'asc'))
      }

      if (this.lastDocSnapshot) {
        searchConstraints.push(startAfter(this.lastDocSnapshot))
      }

      searchConstraints.push(limit(10))

      const constraints = [
        collection(df, `location_pricelist/${state.getPointOfSale().id}/items`),
        ...searchConstraints
      ]

      // const q = query.apply(this, constraints)
      this.currentQuery = query(...constraints)

      return new Promise((resolve, reject) => {
        const listener = onSnapshot(this.currentQuery,
          (snapshot) => {
            let newItemsCount = 0

            snapshot.docChanges().forEach(change => {
              if (change.type === 'added') {
                newItemsCount++
                this.modifyItems(change)
              } else {
                this.modifyItems(change)
              }
            })

            if (snapshot.docs.length > 0) {
              this.lastDocSnapshot = snapshot.docs[snapshot.docs.length - 1]
            }

            resolve(newItemsCount)
          },
          (error) => {
            console.error('Error in items listener:', error)
            reject(error)
          }
        )

        this.listeners.push(listener)
      })

      // const querySnapshot = await getDocs(q)
      // this.lastDocSnapshot = querySnapshot.docs[querySnapshot.docs.length - 1]

      // const results = []
      // querySnapshot.forEach(doc => {
      //   const d = doc.data()
      //   d.stock = d.stock / this.multiplier
      //   d.stock = d.stock.toString().replace('.', ',')
      //   if (d.prices) {
      //     Object.keys(d.prices).forEach(key => {
      //       d.prices[key].currency = key
      //     })
      //   }

      //   results.push({ _id: d.id, ...d })
      // })

      // // if (triGramObject) {
      // //   this.items = results
      // //   this.items = this.items.sort((a, b) => a.name.localeCompare(b.name))
      // //   return 0
      // // }

      // this.items.push(...results)

      // if (triGramObject) {
      //   this.items = this.items.sort((a, b) => a.name.localeCompare(b.name))
      // }
      // this.$refs.categoriesFilter.init(this.items)
      // return results.length
    },
    modifyItems (change) {
      const d = change.doc.data()
      d.stock = d.stock / this.multiplier
      d.stock = d.stock.toString().replace('.', ',')
      if (d.prices) {
        Object.keys(d.prices).forEach(key => {
          d.prices[key].currency = key
        })
      }

      const data = { _id: change.doc.id, ...d }

      if (change.type === 'added') {
        const index = this.items.findIndex(item => item._id === data._id)
        if (index === -1) {
          this.items.unshift(data)
        } else {
          this.$set(this.items, index, data)
        }
      } else if (change.type === 'modified') {
        const index = this.items.findIndex(item => item._id === data._id)
        if (index !== -1) {
          this.$set(this.items, index, data)
        } else {
          this.items.push(data)
        }
      } else if (change.type === 'removed') {
        const index = this.items.findIndex(item => item._id === data._id)
        if (index !== -1) {
          this.items.splice(index, 1)
        }
      }

      if (this.searchObject?.triGramObject) {
        this.items.sort((a, b) => b.name.localeCompare(a.name))
      }

      this.$refs.categoriesFilter.init(this.items)

      // Forsiraj re-render
      this.$forceUpdate()
    },

    // modifyItems (change) {
    //   if (change.type === 'added') {
    //     if (this.items.some(item =>
    //       (item.id === change.doc.data().id) ||
    //       (item.name.toLowerCase() === change.doc.data().name && item.status === 'PROCESSING')
    //     )) {
    //       this.items = this.items.map(item => {
    //         let ret = item
    //         if (
    //           (item.id === change.doc.data().id) ||
    //           (item.name === change.doc.data().name.toLowerCase() && item.status === 'PROCESSING')
    //         ) {
    //           ret = change.doc.data()
    //           ret.stock = ret.stock / this.multiplier
    //           ret.stock = ret.stock.toString().replace('.', ',')
    //         }
    //         if (ret.prices) {
    //           Object.keys(ret.prices).forEach(key => {
    //             ret.prices[key].currency = key
    //           })
    //         }
    //         return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
    //       })
    //     } else {
    //       const d = change.doc.data()
    //       d.stock = d.stock / this.multiplier
    //       d.stock = d.stock.toString().replace('.', ',')
    //       if (d.prices) {
    //         Object.keys(d.prices).forEach(key => {
    //           d.prices[key].currency = key
    //         })
    //       }
    //       this.items.push({ ...d, ...{ [`hover-${change.doc.data().id}`]: false } })
    //     }
    //   }
    //   if (change.type === 'modified') {
    //     this.items = this.items.map(item => {
    //       let ret = item
    //       if (item.id === change.doc.data().id) {
    //         ret = change.doc.data()
    //         ret.stock = ret.stock / this.multiplier
    //         ret.stock = ret.stock.toString().replace('.', ',')
    //       }
    //       if (ret.prices) {
    //         Object.keys(ret.prices).forEach(key => {
    //           ret.prices[key].currency = key
    //         })
    //       }
    //       return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
    //     })
    //   }
    // },
    // infiniteHandler ($state) {
    //   let search = 'name'
    //   if (this.isBarcodeSearch(this.search)) {
    //     search = 'barcode'
    //   } else if (this.isNumber(this.search)) {
    //     search = 'code'
    //   }

    //   const where = this.search ? this.search.toLowerCase() : ''

    //   let query = df.collection(`location_pricelist/${state.getPointOfSale().id}/items`)
    //     .orderBy(search, 'asc')
    //     .limit(10)
    //     .startAfter(this.lastVisible)

    //   if (where) {
    //     query = query.where(search, '>=', where).where(search, '<=', where + '\uf8ff')
    //   }

    //   const listener = query
    //     .onSnapshot((doc) => {
    //       doc.docChanges().forEach((change) => {
    //         this.modifyItems(change)
    //       })
    //       if (doc && !doc.empty) {
    //         if (doc.docs[doc.docs.length - 1]) {
    //           this.lastVisible = doc.docs[doc.docs.length - 1]
    //         }
    //         $state.loaded()
    //       } else {
    //         $state.complete()
    //       }
    //     }
    //     // (error) => {
    //     // }
    //     )

    //   this.listeners.push(listener)
    // },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }
}
</script>
